import axios from 'axios'

import { getCsrfToken } from 'utils/csrf'

const SAFE_METHODS = ['head', 'get']

const requiresForgeryProtection = ({ method, options }) => {
  if (!method) return false
  if (options?.skipCsrfToken) return false

  return !SAFE_METHODS.includes(method.toLowerCase())
}

axios.interceptors.request.use(
  config => {
    config.headers['X-CSRF-Token'] = getCsrfToken()
    return config
  },
  null,
  { runWhen: requiresForgeryProtection }
)
