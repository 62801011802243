window.addEventListener('load', () => {
  const body = document.querySelector('body')
  const hamburger = document.querySelector('.hamburger')
  const navigation = document.querySelector('.navigation')
  const navigationList = document.querySelector('.navigation-list')

  function handleFadeOutTransitionEnd() {
    navigation.classList.remove('visible')
    navigationList.removeEventListener(
      'transitionend',
      handleFadeOutTransitionEnd
    )
  }

  const toggleDrawer = () => {
    body.classList.toggle('scroll-disabled')
    hamburger.classList.toggle('open')

    const isVisible = navigation.classList.contains('visible')

    if (isVisible) {
      navigationList.addEventListener(
        'transitionend',
        handleFadeOutTransitionEnd
      )
      navigationList.classList.remove('fade-in')
    } else {
      navigation.classList.add('visible')

      setTimeout(() => {
        navigationList.classList.add('fade-in')
      }, 100)
    }
  }

  if (hamburger) {
    hamburger.addEventListener('click', toggleDrawer)
  }
})
