import { on } from 'delegated-events'

on('submit', 'form.js-recaptcha-form', e => {
  e.preventDefault()
  const form = e.currentTarget

  // no active recaptcha js
  if (!window.grecaptcha) {
    form.submit()
    return
  }

  const hiddenInput = form.querySelector('input.js-recaptcha-token')
  if (!hiddenInput) {
    form.submit()
    return
  }

  const siteKey = hiddenInput.dataset.rsiteKey
  const actionType = hiddenInput.dataset.ractionType

  if (!siteKey || !actionType) {
    form.submit()
    return
  }

  window.grecaptcha.ready(() => {
    window.grecaptcha
      .execute(siteKey, { action: actionType })
      .then(token => {
        hiddenInput.value = token
        form.submit()
      })
      .catch(() => {
        form.submit()
      })
  })
})
