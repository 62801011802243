import axios from 'axios'
import axiosRetry, { exponentialDelay, isNetworkError } from 'axios-retry'

const isRateLimitExceededError = error => error.response?.status === 429

const retryCondition = error => {
  return isNetworkError(error) || isRateLimitExceededError(error)
}

export const retryDelay = (retryNumber, error = null, delayFactor = 250) =>
  exponentialDelay(retryNumber, error, delayFactor)

axiosRetry(axios, { retries: 3, retryCondition, retryDelay })
