import { onDomReady } from 'utils/onDomReady'

onDomReady(() => {
  const inputs = Array.prototype.slice.apply(
    document.querySelectorAll('input[type="email"]')
  )

  inputs.forEach(input => {
    if (input.setCustomValidity) {
      input.addEventListener('input', e => {
        e.currentTarget.setCustomValidity('')
      })
      input.addEventListener('invalid', e => {
        e.currentTarget.setCustomValidity('Enter valid email address')
      })
    }
  })
})
