import axios from 'axios'

let requestCounter = 0

const updateGlobalCounter = increment => {
  requestCounter += increment
  document?.body?.classList?.toggle('js-pending-request', requestCounter > 0)
}

const onError = error => {
  updateGlobalCounter(-1)

  return Promise.reject(error)
}

axios.interceptors.request.use(config => {
  updateGlobalCounter(+1)

  return config
}, onError)

axios.interceptors.response.use(response => {
  updateGlobalCounter(-1)

  return response
}, onError)

export const resetRequestCounterDuringTests = () => {
  requestCounter = 0
}
