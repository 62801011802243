import _debounce from 'lodash/debounce'

import { IMPORTER_SEARCHED, DEBOUNCE_DELAY } from 'constants/events'
import { trackEvent } from 'api/analytics'

const form = document.querySelector('.integrations-search-form')
const searchField = document.querySelector('.integrations-search-field')

function filterList(searchValue) {
  const list = document.querySelector('.integrations-list-wrapper')
  const items = list.querySelectorAll('li.integrations-item')
  const searchedWord = document.querySelector('.integrations-search-word')

  const processedSearchValue = searchValue.toLowerCase().replace(/\s+/g, '')

  for (let i = 0; i < items.length; i++) {
    const title = items[i].dataset.title
    const match =
      title.toLowerCase().replace(/\s+/g, '').indexOf(processedSearchValue) > -1
    items[i].dataset.hidden = match ? 'false' : 'true'
  }

  const isEmpty = [...items].every(item => item.dataset.hidden === 'true')
  searchedWord.innerText = searchValue
  list.classList.toggle('is-empty', isEmpty)
  list.dataset.filtered = Boolean(searchValue)
}

function handleLogIntegrationsSearch(value) {
  if (!value) return

  trackEvent(IMPORTER_SEARCHED, { search_term: value })
}

const debounceSearchList = _debounce(
  handleLogIntegrationsSearch,
  DEBOUNCE_DELAY
)

if (searchField) {
  searchField.addEventListener('input', event => {
    const { value } = event.target

    filterList(value)

    debounceSearchList(value)
  })

  form.addEventListener('submit', event => {
    event.preventDefault()
  })
}
