import { on } from 'delegated-events'

on('click', '.password-toggle-btn', e => {
  const button = e.currentTarget
  const input = button.parentElement.querySelector('input')
  if (!input) {
    return
  }

  button.classList.toggle('password-toggle-show-state')
  const isVisiblePassword = button.classList.contains(
    'password-toggle-show-state'
  )
  const inputType = isVisiblePassword ? 'text' : 'password'
  input.setAttribute('type', inputType)
})
