import axios from 'api/transport'

// #goal avoid this request for feature specs to make them more stable and faster
// #approach JS tests to mock this module to allow for selective testing
const currentEnv = process.env.RAILS_ENV || 'development'
const isTest = currentEnv === 'test'

export const trackEvent = (event, payload) => {
  if (isTest) {
    return Promise.resolve()
  }

  if (window.amplitude) {
    window.amplitude.getInstance().logEvent(`Web:${event}`, payload)
  }

  return axios.post('/api/analytics', {
    event,
    timestamp: Date.now(),
    payload
  })
}
