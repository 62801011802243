import { on } from 'delegated-events'

const NAVIGATION_BUTTON = '.marketing-navigation-item-button'
const NAVIGATION_MENU = '.marketing-navigation-submenu'
const NAVIGATION_ITEM = '.marketing-navigation-item'
const NAVIGATION_OVERLAY = '.marketing-navigation__overlay'

const ACTIVE_CLASSNAME = 'active'

const navigationOverlay = document.querySelector(NAVIGATION_OVERLAY)
const buttons = document.querySelectorAll(NAVIGATION_BUTTON)
const submenus = document.querySelectorAll(NAVIGATION_MENU)

const isNormalScreen = window.matchMedia('(min-width: 1024px)').matches

const removeItemActive = () => {
  submenus.forEach(submenu => {
    submenu.style.maxHeight = ''
  })

  buttons.forEach(button => {
    button.closest(NAVIGATION_ITEM).classList.remove(ACTIVE_CLASSNAME)
  })
}

const closeNavigation = () => {
  submenus.forEach(submenu => {
    submenu.style.transition = ''
  })

  navigationOverlay.classList.remove(ACTIVE_CLASSNAME)

  removeItemActive()
}

const handleButtonClick = event => {
  const parentItem = event.target.closest(NAVIGATION_ITEM)
  const submenuItem = event.target.nextElementSibling
  const isActive = parentItem.classList.contains(ACTIVE_CLASSNAME)
  const contentHeight = submenuItem.scrollHeight

  const hasActiveItem =
    Array.from(
      document.querySelectorAll(`${NAVIGATION_ITEM}.${ACTIVE_CLASSNAME}`)
    ).length > 0

  if (isNormalScreen && hasActiveItem) {
    submenus.forEach(submenu => {
      submenu.style.transition = isActive ? '' : 'none'
    })

    navigationOverlay.classList.remove(ACTIVE_CLASSNAME)
  }

  removeItemActive()

  if (!isActive) {
    parentItem.classList.add(ACTIVE_CLASSNAME)
    submenuItem.style.maxHeight = `${contentHeight}px`

    if (isNormalScreen) {
      navigationOverlay.classList.add(ACTIVE_CLASSNAME)
    }
  }
}

on('click', NAVIGATION_BUTTON, handleButtonClick)

on(
  'click',
  `.marketing-navigation-submenu__action--video, ${NAVIGATION_OVERLAY}`,
  closeNavigation
)
