import axios from 'axios'

import { requireLogin } from 'services/requireLogin'

const FORBIDDEN_ERROR = 403
const EXCLUDED_ROUTES = [
  /api\/integrations\/(?!(destinations|sources)\/?)/,
  /api\/credentials\/apple_search_ads/
]

const isExcludedRoute = url =>
  EXCLUDED_ROUTES.some(routeRegex => routeRegex.test(url))

axios.interceptors.response.use(
  response => response,
  error => {
    const responseCode = error.response && error.response.status
    if (
      responseCode === FORBIDDEN_ERROR &&
      !isExcludedRoute(error.request.responseURL)
    ) {
      return requireLogin(window.location)
    }
    return Promise.reject(error)
  }
)
