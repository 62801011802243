window.addEventListener('load', () => {
  const items = document.querySelectorAll('.accordion-item > button')

  function toggleAccordion({ currentTarget: button }) {
    const isExpanded = button.getAttribute('aria-expanded') === 'true'
    const toggleTo = isExpanded ? 'false' : 'true'

    const content = button.nextElementSibling
    const contentHeight = content.scrollHeight

    const transitionSpeed = (contentHeight * 1.25).toFixed(2)

    content.style.transition = `max-height ${transitionSpeed}ms ease-out, opacity ${transitionSpeed}ms ease-out`
    content.style.maxHeight = isExpanded ? null : `${contentHeight}px`

    button.setAttribute('aria-expanded', toggleTo)
  }

  items.forEach(item => item.addEventListener('click', toggleAccordion))
})
